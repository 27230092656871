<template>
  <article class="message panel__section">
    <section
      class="action-bar panel__section-item panel__section-item--no-padding"
    >
      <slot name="actions" />
    </section>

    <section class="info panel__section-item">
      <header>
        <h1 v-test:subject v-text="message.subject" />
        <div class="attachments-and-date">
          <Dropdown v-if="attachments.length" class="dropdown--align-left">
            <template v-slot:button="dropdown">
              <button
                type="button"
                class="button--clean attachments"
                :aria-label="attachmentsButtonLabel"
                @click="dropdown.toggle"
              >
                <Icon symbol="attach" role="presentation" />
                {{ attachmentsButtonLabel }}
                <Icon symbol="arrow-down" class="arrow" />
              </button>
            </template>

            <template v-slot:content="dropdown">
              <ul class="link-list attachments">
                <li
                  v-if="attachments.length > 1 && attachmentsDownloadLink"
                  class="download-all"
                >
                  <DownloadLink
                    :url="attachmentsDownloadLink"
                    class="link-list__item"
                    @done="dropdown.toggle"
                  >
                    <Icon symbol="download" />
                    <translate>Download all</translate>
                  </DownloadLink>
                </li>
                <li
                  v-for="(file, index) in attachments"
                  :key="file.id"
                  :class="{ separated: index === 0 }"
                >
                  <DownloadLink
                    class="link-list__item"
                    :url="file.url"
                    :filename="file.filename"
                    @done="dropdown.toggle"
                  >
                    <Icon symbol="file" />
                    <span class="filename" v-text="file.filename" />
                    <span>({{ file.size | formatDataSize }})</span>
                  </DownloadLink>
                </li>
              </ul>
            </template>
          </Dropdown>
          <time v-text="relativeDate" :datetime="message.date" />
        </div>
      </header>
      <dl>
        <dt v-translate>From:</dt>
        <dd class="addresses">
          <RecipientBadge
            v-test:from
            :official="message.official"
            :show-badge-dropdown="showBadgeDropdown"
            :value="message.from"
          />
        </dd>
        <dt v-translate>To:</dt>
        <dd class="addresses">
          <RecipientBadge
            v-for="(recipient, index) in message.to"
            v-test:to
            :hide-email="true"
            :key="index"
            :show-badge-dropdown="showBadgeDropdown"
            :value="recipient"
          />
        </dd>
        <template v-if="message.cc.length">
          <dt v-translate>Cc:</dt>
          <dd class="addresses">
            <RecipientBadge
              v-for="(recipient, index) in message.cc"
              v-test:cc
              :hide-email="true"
              :key="index"
              :show-badge-dropdown="showBadgeDropdown"
              :value="recipient"
            />
          </dd>
        </template>
        <template v-if="message.bcc.length">
          <dt v-translate>Bcc:</dt>
          <dd class="addresses">
            <RecipientBadge
              v-for="(recipient, index) in message.bcc"
              v-test:bcc
              :hide-email="true"
              :key="index"
              :show-badge-dropdown="showBadgeDropdown"
              :value="recipient"
            />
          </dd>
        </template>
        <template v-if="message.reply_to.length">
          <dt v-translate>Reply-To:</dt>
          <dd class="addresses">
            <RecipientBadge
              v-for="(recipient, index) in message.reply_to"
              v-test:replyTo
              :hide-email="true"
              :key="index"
              :show-badge-dropdown="showBadgeDropdown"
              :value="recipient"
            />
          </dd>
        </template>
        <div class="date">
          <dt v-translate>Date:</dt>
          <dd>
            <time v-text="absoluteDate" :datetime="message.date" />
          </dd>
        </div>
      </dl>
      <slot name="top" :message-decryption-status="decryptionStatus" />
      <transition name="fade">
        <div
          class="external-images"
          v-if="hasExternalImages && !showExternalImages"
        >
          <Icon class="icon" symbol="image" />
          <p>
            <translate>
              External images are hidden for privacy and security reasons.
            </translate>
            <button
              type="button"
              class="button button--small"
              @click="$emit('showExternalImages')"
              v-translate
            >
              Show images
            </button>
          </p>
        </div>
      </transition>
    </section>

    <DecryptMessage
      v-if="encryptedMessage"
      :message-id="message.id"
      :message-encryption-status="decryptionStatus"
      @decryptionStatus="setDecryptionStatus"
    />
    <MessageBody
      v-else
      :body="message.body"
      :attachments="message.files"
      :element-container-class="message.css_prefix"
      :show-external-images="showExternalImages"
      :external-link-warning="externalLinkWarning"
      @hasExternalImages="hasExternalImages = $event"
      @mailToClicked="$emit('mailToClicked', arguments)"
      ref="messageBody"
      :dark-mode="darkMode"
    />
  </article>
</template>

<script>
  import Dropdown from '@/components/Dropdown/Dropdown';
  import DecryptMessage from '@/components/DecryptMessage/DecryptMessage';
  import Icon from '@/components/Icon/Icon';
  import RecipientBadge from '@/components/RecipientBadge/RecipientBadge';
  import MessageBody from '@/components/MessageBody/MessageBody';
  import formatDataSize from '@/lib/formatDataSize';
  import { mixin as timeMixin } from '@/lib/time';
  import { getDateFormat, getTimeFormat } from '@/lib/dateTimeFormats';
  import DownloadLink from '@/components/DownloadLink';

  const PGP_MESSAGE_DECRYPTED_STATUS = 0;

  export default {
    name: 'Message',
    components: {
      DecryptMessage,
      DownloadLink,
      Dropdown,
      Icon,
      MessageBody,
      RecipientBadge,
    },
    filters: {
      formatDataSize,
    },
    mixins: [timeMixin],
    props: {
      message: {
        type: Object,
        required: true,
      },
      attachmentsDownloadLink: {
        type: String,
        default: null,
      },
      showExternalImages: {
        type: Boolean,
        default: false,
      },
      externalLinkWarning: {
        type: Boolean,
        default: false,
      },
      showBadgeDropdown: {
        type: Boolean,
        default: false,
      },
      displayFormats: {
        type: Object,
        required: false,
        default: () => ({
          date: '',
          time: '',
        }),
      },
      darkMode: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        hasExternalImages: false,
        decryptionStatus: this.message.pgp?.encryption.status,
      };
    },
    computed: {
      absoluteDate() {
        return this.formatDate(
          this.message.date,
          'dddd DD MMMM YYYY [at] HH:mm:ss'
        );
      },
      attachments() {
        return (this.message.files || []).filter((file) => !file.inline);
      },
      attachmentsButtonLabel() {
        return this.$gettextInterpolate(
          this.$ngettext(
            '%{num} attachment',
            '%{num} attachments',
            this.attachments.length
          ),
          { num: this.attachments.length }
        );
      },
      encryptedMessage() {
        return (
          this.message.flags?.is_encrypted &&
          this.decryptionStatus !== PGP_MESSAGE_DECRYPTED_STATUS
        );
      },
      relativeDate() {
        const dateFormat = getDateFormat(this.displayFormats.date);
        const timeFormat =
          getTimeFormat(this.displayFormats.time) === '12h' ? 'h:mm a' : 'H:mm';
        const dateString = this.$gettextInterpolate(
          this.$gettext('%{ dateFormat } [at] %{ timeFormat }'),
          { dateFormat, timeFormat }
        );

        return this.fromNow(this.message.date, {
          sameDay: this.$gettextInterpolate(
            this.$gettext(`[Today at] %{ timeFormat }`),
            { timeFormat }
          ),
          lastWeek: dateString,
          lastDay: dateString,
          lastYear: dateString,
          sameElse: dateString,
        });
      },
    },
    methods: {
      setDecryptionStatus(error) {
        this.decryptionStatus = error;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .action-bar {
    inset-block-start: 0;
    position: sticky;
    z-index: $layer-raised;
  }

  .info {
    display: flex;
    flex-flow: column;
    gap: $spacing-default;

    > header {
      align-items: flex-start;
      display: flex;
      flex-flow: wrap;
      gap: $spacing-default;
      overflow-wrap: break-word;

      > * {
        min-width: 0; // avoid overflow
      }

      > h1 {
        flex: auto;
        font-size: $font-size-l;
        line-height: normal; // override unfortunate global styles
      }

      > .attachments-and-date {
        display: flex;
        gap: $spacing-default;
        margin-inline-start: auto;
      }

      time {
        display: none;
      }

      @media screen and (width >= $messages-single-line-breakpoint) {
        h1 {
          font-size: $font-size-xl;
        }
        time {
          display: unset;
        }
      }
    }

    > dl {
      align-items: start;
      display: grid;
      gap: $spacing-default;
      grid: auto / fit-content(20ch);
      overflow-wrap: anywhere;

      > * {
        min-width: 0; // avoid overflow
      }

      > dt {
        grid-column: 1;
      }

      > dd {
        grid-column: 2;

        &.addresses {
          // align using the text in recipient badges as anchor
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: $spacing-xxs;
          margin-block: -$spacing-xxs 0;
          margin-inline-start: -$spacing-xs;
          min-width: 0;
        }
      }

      > div {
        display: contents; // only for grouping in dom
      }

      @media screen and (width >= $messages-single-line-breakpoint) {
        .date {
          display: none; // shown elsewhere on wider screens
        }
      }
    }
  }

  .external-images {
    align-items: baseline;
    display: flex;
    gap: $spacing-s;

    .icon {
      // Icon component does not align nicely with surrounding text 😿
      inset-block: $spacing-xxs;
      position: relative;
    }
  }

  button.attachments {
    align-items: center;
    border: none;
    color: $brand-blue;
    display: inline-flex;
    gap: $spacing-xxs;
    padding: 0;

    @media print {
      .arrow {
        display: none;
      }
    }
  }

  ul.attachments {
    > li {
      a {
        display: flex;
        gap: $spacing-xxs;

        @media screen and (width < $screen-xs) {
          // hacky way to avoid a very narrow dropdown list
          width: 100vw;

          .filename {
            word-break: break-word;
          }
        }
      }

      &.download-all {
        border-block-end: 1px solid $gray-400;

        a {
          color: $brand-blue;
        }
      }
    }
  }
</style>
